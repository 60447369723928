body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000 !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='338' height='40.6' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23222' stroke-width='7' stroke-opacity='0.65'%3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E") !important;
}
.about-container {
  background-color: #121212 !important;
  color: white;
  margin-bottom: 20px;
}
.settings-div {
  background-color: #121212;
  width: 90%;
  margin-left: 5%;
  padding: 30px;
  border-radius: 20px;
  border: 2px solid white;
}
.about-container {
  padding: 30px !important;
  border-radius: 20px;
  border: 2px solid white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.key-grid {
  cursor: pointer;
}
.bordered {
  color: mediumslateblue !important;
}
.ant-slider {
  width: 70%;
  margin-left: 15% !important;
}
.ant-slider-track {
  background-color: mediumslateblue !important;
  height: 15px !important;
}
.ant-slider-step {
  color: mediumslateblue !important;
}
.ant-slider:hover .ant-slider-track {
  background-color: darkslateblue;
}
.ant-slider-step,
.ant-slider-rail {
  height: 15px !important;
}
.ant-slider-handle {
  width: 25px !important;
  height: 25px !important;
  border: 2px solid darkslateblue !important;
}
.ant-switch-checked {
  background-color: mediumslateblue !important;
}
.ant-switch {
  margin-left: calc(50% - 30px) !important;
}
#waveform,
#waveform2 {
  background-color: #000000;
}
.ui.primary.button {
  background-color: mediumslateblue !important;
}
.ui.primary.button:hover {
  background-color: darkslateblue !important;
}
button.ui.massive.secondary.button {
  margin-left: calc(50% - 145px) !important;
  display: block !important;
  overflow: hidden !important;
  width: 290px !important;
}
#title-h1 {
  text-align: center !important;
}
.settings-h3 {
  color: white;
}
@media screen and (max-width: 900px) {
  #title-h1 {
    margin-top: 15vh !important;
  }
}
@media screen and (min-width: 900px) {
  .settings-div {
    width: 70%;
    margin-left: 15%;
  }
  .settings-h3 {
    margin-left: 15% !important;
  }
}
